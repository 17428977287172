<template>
    <div class="row mt-6">
        <div class="col-12 flex-column align-start">
            <div class="order-attributes mt-3">
                <div class="flex-column half-width">
                    <div class="flex-between">
                        <div class="bold">{{ $t('Item') }}</div>
                        <div>{{ activeItemIndex+1 }}/{{ orders.length }}</div>
                    </div>
                    <div class="flex-between">
                        <div class="bold">{{ $t('SO number') }}</div>
                        <div>{{ activeItem.sales_order_number || '' }}</div>
                    </div>
                    <div class="flex-between">
                        <div class="bold">{{ $t('Article') }}</div>
                        <div>{{ activeItem.article || '' }}</div>
                    </div>
                    <div class="flex-between">
                        <div class="bold">{{ $t('Model number') }}</div>
                        <div>{{ activeItem.model_number || '' }}</div>
                    </div>
                    <div class="flex-between">
                        <div class="bold">{{ $t('Customer') }}</div>
                        <div>{{ activeItem.customer || '' }}</div>
                    </div>
                    <div class="flex-between">
                        <div class="bold">{{ $t('Customer order number') }}</div>
                        <div>{{ activeItem.customer_order_number || '' }}</div>
                    </div>
                    <div class="flex-between">
                        <div class="bold">{{ $t('Project') }}</div>
                        <div>{{ activeItem.project || '' }}</div>
                    </div>
                </div>
                <div class="flex-column half-width">
                    <div class="flex-between">
                        <div class="bold">{{ $t('Order delivery date') }}</div>
                        <div>{{ activeItem.order_delivery_date || '' }}</div>
                    </div>
                    <div class="flex-between">
                        <div class="bold">{{ $t('Warranty') }}</div>
                        <div>{{ activeItem.warranty || '' }}</div>
                    </div>
                    <div class="flex-between">
                        <div class="bold">{{ $t('Warranty case') }}</div>
                        <div>{{ activeItem.warranty_case || '' }}</div>
                    </div>
                </div>
            </div>
            <ValidationObserver class="w-100" v-slot="{ validate }" ref="observer">
                <form
                    class="form mt-5 mb-5 order-attributes"
                    @submit.prevent="validate(submitForm())"
                >
                    <div class="flex-column half-width align-start">
                        <div class="form-group">
                            <ValidationProvider
                                v-slot="{ errors }"
                                rules="required|min:3"
                            >
                                <SfInput
                                    :valid="!errors[0]"
                                    :error-message="$t(errors[0])"
                                    v-model="mainFormFields.endUser"
                                    required
                                    name="endUser"
                                    :label="$t('End user')"
                                    class="form__element"
                                    @blur="saveMainForm()"
                                />
                            </ValidationProvider>
                        </div>
                        <div class="form-group">
                            <ValidationProvider
                                v-slot="{ errors }"
                                rules="required|numeric"
                            >
                                <SfInput
                                    :valid="!errors[0]"
                                    :error-message="$t(errors[0])"
                                    v-model="mainFormFields.quantity"
                                    required
                                    name="quantity"
                                    :label="$t('Quantity')"
                                    class="form__element"
                                    @blur="saveMainForm()"
                                />
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="flex-column half-width align-start">
                        <div class="form-group mt-3 relative-pos">
                            <ValidationProvider
                                v-slot="{ errors }"
                                rules="required"
                            >
                                <div class="attribute-tooltip flex">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                        height="24"
                                        width="24"
                                    />
                                    <span class="attribute-tooltiptext" v-html="attributeInfo['preferredCourseOfActionTooltip']"></span>
                                </div>
                                <v-select
                                    :clearable="false"
                                    label="label"
                                    key="label"
                                    :options="courseOfActions"
                                    :placeholder="$t('Preferred course of action')"
                                    v-model="mainFormFields.preferredCourseOfAction"
                                    @input="saveMainForm()"
                                />
                                <p v-if="errors[0]" class="sf-input__error-message">
                                    {{ $t(errors[0]) }}
                                </p>
                            </ValidationProvider>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
            <div class="flex-between w-100">
                <div>
                    <h4>{{ $t('Product issues') }}</h4>
                </div>
                <div v-if="displayIssuesWarning" class="warning-message">
                    {{ $t('Please add at least one issue to proceed') }}
                </div>
            </div>
            <vue-good-table
                ref="issueList"
                :columns="columnsData"
                :rows="items"
                :pagination-options="config.paginationOptions"
            >
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ props.column.label }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field !== 'actions'">
                        {{
                            isDateField(props.column.field)
                                ? formatDate(props.formattedRow[props.column.field])
                                : (props.column.field === 'issue_category'
                                    ? props.formattedRow.issue_category.label
                                    : props.formattedRow[props.column.field])
                        }}
                    </span>
                    <div v-else @click="removeItem(props.rowIndex)" class="pointer">
                        <svg-image class="icon" icon="close" height="16" width="16" />
                    </div>
                </template>
            </vue-good-table>
            <div
                class="button medium button-primary mt-5"
                @click="isModalOpen = true"
            >
                    {{ $t('Add') }}
            </div>
        </div>
        <SfModal
            :visible="isModalOpen"
            class="project-modal"
            @close="isModalOpen = false"
        >
            <div class="container">
                <ValidationObserver v-slot="{ handleSubmit }" key="addIssue">
                    <form class="form" @submit.prevent="handleSubmit(addIssue)" enctype="multipart/form-data">
                        <div class="row mt-5">
                            <div class="col-12">
                                <div class="form-group">
                                    <ValidationProvider
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <v-select
                                            :clearable="false"
                                            label="label"
                                            key="label"
                                            required
                                            :placeholder="$t('Issue category')"
                                            :options="issuesCategories"
                                            v-model="issueFormFields.issue_category"
                                        />
                                        <p v-if="errors[0]" class="sf-input__error-message">
                                            {{ $t(errors[0]) }}
                                        </p>
                                    </ValidationProvider>

                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-6">
                                <div class="form-group">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        rules="required|numeric"
                                    >
                                        <SfInput
                                            :valid="!errors[0]"
                                            :error-message="$t(errors[0])"
                                            v-model="issueFormFields.quantity"
                                            required
                                            name="quantity"
                                            :label="$t('Quantity')"
                                            class="form__element text-italic"
                                            autocomplete="off"
                                        />
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-6">
                                <div class="form-group">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        rules="required|past_date"
                                    >
                                        <vue-datepicker
                                            :format="'DD-MM-YYYY'"
                                            :required="true"
                                            class="form__element"
                                            :label="$t('Installation date')"
                                            v-model="issueFormFields.installation_date"
                                            :disabled-date="disabledAfterToday"
                                        />
                                        <p v-if="errors[0]" class="sf-input__error-message">
                                            {{ $t(errors[0]) }}
                                        </p>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        rules="required|past_date"
                                    >
                                        <vue-datepicker
                                            :format="'DD-MM-YYYY'"
                                            :required="true"
                                            class="form__element"
                                            :label="$t('Failure date')"
                                            v-model="issueFormFields.failure_date"
                                            :disabled-date="disabledAfterToday"
                                        />
                                        <p v-if="errors[0]" class="sf-input__error-message">
                                            {{ $t(errors[0]) }}
                                        </p>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12">
                                <div class="form-group">
                                    <ValidationProvider v-slot="{ errors }" rules="required|min:3">
                                        <SfInput
                                            type="text_field"
                                            :valid="!errors[0]"
                                            :error-message="$t(errors[0])"
                                            v-model="issueFormFields.description"
                                            required
                                            name="description"
                                            :label="$t('Description')"
                                            class="form__element"
                                            autocomplete="off"
                                        />
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <div class="form-group image-upload">
                                    <ValidationProvider v-slot="{ errors }">
                                        <div class="attribute-tooltip flex">
                                            <svg-image
                                                class="icon hover-icon"
                                                icon="info"
                                                height="24"
                                                width="24"
                                            />
                                            <span class="attribute-tooltiptext" v-html="attributeInfo['pictures']"></span>
                                        </div>
                                        <label class="file-upload-label">
                                            <span class="upload-text">
                                                <svg-image
                                                    class="icon hover-icon"
                                                    icon="upload"
                                                    height="24"
                                                    width="24"
                                                />
                                                {{ $t("Upload Images") }}
                                            </span>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                :valid="!errors[0]"
                                                :error-message="$t(errors[0])"
                                                name="installation_images"
                                                class="hidden"
                                                multiple
                                                @change="handleImageUpload"
                                            />
                                        </label>
                                        <p v-if="errors[0]" class="sf-input__error-message">
                                            {{ $t(errors[0]) }}
                                        </p>
                                        <div v-if="selectedImages.length" class="image-preview">
                                            <!-- Display selected file names -->
                                            <ul v-if="selectedImages.length" class="file-list">
                                                <li v-for="(image, index) in selectedImages" :key="index" class="file-item">
                                                    <span class="file-name" :title="image.file.name">
                                                        {{ truncateFileName(image.file.name, 34) }}
                                                    </span>
                                                    <div @click="removeImage(index)" class="pointer">
                                                        <svg-image class="icon" icon="close" height="16" width="16" />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <Button
                            type="submit"
                            class="mt-4 button medium button-secondary"
                        >
                            {{ $t('Add') }}
                        </Button>
                    </form>
                </ValidationObserver>
            </div>
        </SfModal>
    </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import {
    defineComponent,
    computed,
    ref,
    watch,
    onMounted,
    useContext,
} from '@nuxtjs/composition-api';
import { SfInput, SfModal, SfLoader } from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, numeric, min } from "vee-validate/dist/rules";
import { pagination } from '~/helpers/table';
import { useClaimSubmission } from '../index';
import VueDatepicker from '~/components/Common/Calendar/VueDatepicker.vue';
import { format } from 'date-fns';

extend('required', {
	...required,
	message: 'This field is required',
});

extend('numeric', {
  ...numeric,
  message: 'This field must be a number',
});

extend('min', {
  ...min,
  message: 'This field have more than 3 characters',
});

extend('past_date', {
    validate(value) {
        // Ensure `value` is a valid Date object
        const selectedDate = value instanceof Date ? value : new Date(value);
        if (isNaN(selectedDate)) return false; // Invalid date handling

        const today = new Date();

        return selectedDate <= today; // Ensures the selected date is in the past
    },
    message: 'The date must be in the past.',
});

export default defineComponent({
    name: 'Step2',
    components: {
		SfLoader,
        SfInput,
        SfModal,
        ValidationProvider,
        ValidationObserver,
        VueGoodTable,
        VueDatepicker
	},
    props: {
        activeItemIndex: Number,
        orders: Array,
        displayIssuesWarning: Boolean,
        mainFormFields: Object,
	},
    emits: ['updateIssues','removeIssue', 'saveMainForm'],
    setup(props, { emit }) {
        const { app } = useContext();
        const apiState = app.$vsf.$magento.config.state;
        const activeItem = computed(() => props.orders[props.activeItemIndex] ?? {});
        const currentItems = computed(() => props.orders[props.activeItemIndex].issues ?? []);
        const isModalOpen = ref(false);
        const uploadedImages = ref([]);
        const { fetchDropdownOptions, dropdownOptions } = useClaimSubmission();

        const attributeInfo = {
            pictures: `
                <p>Please attach:</p>
                <ul>
                    <li>Label photo with matching sales order number, article, and model number</li>
                    <li>Photo of visual defect when applicable</li>
                </ul>
            `,
            preferredCourseOfActionTooltip: "You can select one of the options below to indicacte how you would like to proceed. We will take this into account and either act accordingly or contact you for more details as soon as possible. Please note that by choosing option 2 and 3, components will be invoiced to you and credited only when they are fully returned for diagnostics and a test report is created concluding amnufacturer's fault.",
        };

        const config = ref({
			columnWidth: 460,
			gap: 15,
			rtl: false,
			ssrColumns: 1,
			paginationOptions: pagination(5),
		});

        const columnsData = ref([
            { label: 'Issue category', field: 'issue_category' },
            { label: 'Quantity', field: 'quantity' },
            { label: 'Installation date', field: 'installation_date' },
            { label: 'Failure date', field: 'failure_date' },
            { label: 'Description', field: 'description' },
            { label: 'Actions', field: 'actions' }
        ]);

        const issuesCategories = computed(() => dropdownOptions.value?.issue_categories ?? []);
        const courseOfActions = computed(() => dropdownOptions.value?.preferred_actions ?? []);
        const items = ref([]);

        const issueFormFields = ref({
            issue_category: '',
            quantity: '',
            installation_date: '',
            failure_date: '',
            description: '',
            pictures: '',
        });

        const addIssue = () => {
            const newIssue = {
                issue_category: issueFormFields.value.issue_category,
                quantity: issueFormFields.value.quantity,
                installation_date: issueFormFields.value.installation_date,
                failure_date: issueFormFields.value.failure_date,
                description: issueFormFields.value.description,
                pictures: uploadedImages.value.map(img => img.file),
            };

            emit('updateIssues', [...items.value, newIssue]);
            clearIssueForm();

            isModalOpen.value = false;
        }

        const clearIssueForm = () => {
            issueFormFields.value = {
                issue_category: '',
                quantity: '',
                installation_date: '',
                failure_date: '',
                description: '',
                pictures: '',
            };
            uploadedImages.value = [];
        }

        const removeItem = (index) => {
            emit('removeIssue', index);
        }

        const setCourseOfAction = (event) => {
            props.orders[props.activeItemIndex].preferredCourseOfAction = event || null;
        }

        const saveMainForm = () => {
            emit('saveMainForm', props.mainFormFields);
        };

        const handleImageUpload = (event) => {
            const files = event.target?.files;
            if (!files) return;

            uploadedImages.value = [
                ...uploadedImages.value,
                ...Array.from(files).map(file => ({ file }))
            ];
        };

        const removeImage = (index) => {
            uploadedImages.value.splice(index, 1);
        };

        const truncateFileName = (name, maxLength) => {
            return name.length > maxLength ? name.substring(0, maxLength) + "..." : name;
        }

        const formatDate = (date) => {
            return date ? format(new Date(date), "MMMM d, yyyy") : "";
        };

        const isDateField = (fieldName) => {
            return fieldName.toLowerCase().includes("date");
        };

        const disabledAfterToday = (date) => {
            const today = new Date();
            return date > today;
        };

        watch(() => props.orders[props.activeItemIndex]?.issues, (newIssues) => {
            items.value = newIssues ? [...newIssues] : [];
        }, { immediate: true });

        onMounted( async () => {
            await fetchDropdownOptions();
        });

        return {
            dateFormat: computed(() => apiState.getLocale() == 'us' ? 'MM-DD-YYYY' : 'DD-MM-YYYY'),
            activeItem,
            config,
            items,
            columnsData,
            isModalOpen,
            issueFormFields,
            handleImageUpload,
            addIssue,
            currentItems,
            removeItem,
            issuesCategories,
            courseOfActions,
            setCourseOfAction,
            attributeInfo,
            saveMainForm,
            removeImage,
            selectedImages: uploadedImages,
            truncateFileName,
            formatDate,
            isDateField,
            disabledAfterToday
        };
    },
});
</script>

<style lang="scss" scoped>
    .order-attributes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        align-items: flex-start;

        @media (min-width: 1200px) {
            flex-wrap: nowrap;
        }

        div {
            width: 100%;
        }

        .attribute-tooltip {
            top: 4px;
            right: -2rem;
            padding-right: 16px;
            z-index: 10;
            max-width: 26px;
            cursor: pointer;
        }

        .attribute-tooltiptext {
            max-width: 360px !important;

            @media (min-width: 768px) {
                max-width: 600px !important;
            }
        }
    }

    .half-width {
        width: 44%;
        padding-right: 1rem;
    }
    .form__element {
        width: 100%;
    }

    ::v-deep .v-select .vs__selected {
        white-space: normal !important;
        line-height: 1.4;
    }

    ::v-deep .v-select .vs__dropdown-menu li {
        white-space: normal;
    }

    .input-row {
        width: 100%;
        gap: 20px;
        flex-wrap: wrap;

        & > div {
            width: 45%;
        }
    }

    .flex-row {
        flex-wrap: wrap;
        gap: 20px;
    }

    .flex-between {
        margin: 3px 0;
    }

    @media (min-width: 768px) {
        .input-row .form__element {
            width: calc(50% - 10px); /* 2 inputs per row with some space */
        }
    }

    .half-width {
        .form-group {
            gap: 1rem; /* Adjust spacing between inputs */
            width: 70%;
        }
    }

    .form__element {
        flex: 1; /* Makes inputs take equal width */
    }

    .table-container {
        width: 100%;
    }

    .vue-good-table {
        width: 100%;
    }

    .vgt-wrap  {
        width: 100%;
    }

    #add-to-cart-modal {
        .container {
            text-align: center;
        }

        .sf-loader {
            height: 440px;
        }

        p {
            font-size: 14px;
            color: var(--_c-dark-secondary);
        }

        .button:not(.small) {
            &.download {
                margin-top: 35px;
                margin-bottom: 0px;
                display: inline-block;
            }

            @include for-desktop() {
                font-size: 16px;
                line-height: 20px;
                padding: 8px 28px;
            }
        }

        .heading {
            max-width: 248px;

            button {
                min-width: 240px;
            }
        }
    }

    @include for-desktop() {
        .add-to-cart-modal {
            --modal-width: 29.375rem !important;
        }
    }

    .request-price {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 1;
        padding: 5px 8px;

        &.disabled {
            border: none;
        }
    }
    .warning-message {
        color: var(--_c-red-primary);
        min-height: 30px;
    }

    .file-upload-label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        border: 2px dashed #ccc;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s;
        background-color: #fafafa;

        .hidden {
            width: 1px;
            height: 1px;
        }
    }

    .file-upload-label:hover {
        border-color: #007bff;
        background-color: #f0f8ff;
    }

    .upload-text {
        display: flex;
        align-items: center;
        text-align: center;
        gap: 8px;
        font-weight: 500;

        .svg-image {
            position: absolute;
        }
    }

    .upload-icon {
        font-size: 18px;
        color: #007bff;
    }

    .error-text {
        color: var(--c-danger);
        font-size: 14px;
        word-wrap: break-word;
        white-space: normal;
    }

    .attribute-tooltiptext {
        position: absolute;
        bottom: 30px;
        right: 0px;
        padding: 6px 10px;
        border-radius: 4px;
        display: none;
        background-color: var(--c-lighter-gray);
        border: 1px solid var(--c-light-gray);
        z-index: 1000;
    }

    .image-upload {
        .attribute-tooltiptext {
            bottom: 30px !important;
            right: 0px !important;
        }
    }

    .icon:hover + .attribute-tooltiptext {
        display: block;
    }

    /* File List */
    .file-list {
        margin-top: 10px;
        list-style: none;
        padding: 0;
    }

    .file-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        background: #f5f5f5;
        border-radius: 5px;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
    }

    .file-name {
        max-width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .remove-btn {
        background: red;
        color: white;
        border: none;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 12px;
        cursor: pointer;
    }
</style>