<template>
    <div class="container mt-5">
        <div class="order-summary mb-5" v-if="groupedClaims">
            <div
                v-for="(luminaires, salesOrderNumber) in groupedClaims"
                :key="salesOrderNumber"
                class="order-summary__group mb-3"
            >
                <!-- Main attributes (once per sales order) -->
                <div class="main-attributes flex align-start mb-2">
                    <div class="order-summary__item mr-5">
                        <span class="subtext">{{ formatLabel('sales_order_number') }}</span>
                        <span class="underline bold">{{ salesOrderNumber }}</span>
                    </div>
                    <div class="order-summary__item mr-5">
                        <span class="subtext">{{ formatLabel('customer') }}</span>
                        <span class="underline bold"> {{ luminaires[0].customer }}</span>
                    </div>
                    <div class="order-summary__item mr-5">
                        <span class="subtext">{{ formatLabel('order_delivery_date') }}</span>
                        <span class="underline bold">{{ luminaires[0].order_delivery_date }}</span>
                    </div>
                </div>
                <!-- Secondary attributes (per luminaire) -->
                <div
                    v-for="luminaire in luminaires"
                    :key="luminaire.article"
                    class="secondary-attributes ml-5 flex"
                >
                    <div
                        v-for="(value, key) in luminaire"
                        :key="key"
                    >
                        <div v-if="propertiesTodDisplay.includes(key)" class="order-summary__item mr-3">
                            <span class="subtext">{{ formatLabel(key) }}</span>
                            <span class="">{{ formatValue(value) }}</span>
                        </div>
                    </div>
                    <div>
                        <div class="order-summary__item mr-3">
                            <span class="subtext">{{ formatLabel('issue') }}</span>
                            <span class="">{{ formatValue(luminaire['issues'][0]['issue_category']['label']) }}</span>
                        </div>
                    </div>
                    <div>
                        <div class="order-summary__item mr-3">
                            <span class="subtext">{{ formatLabel('failure_date') }}</span>
                            <span class="">{{ formatDate(luminaire['issues'][0]['failure_date']) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <form class="form order-attributes" enctype="multipart/form-data">
            <div class="flex-column half-width align-start">
                <div class="form-group">
                    <ValidationProvider
                        v-slot="{ errors }"
                        rules="required|min:3"
                    >
                        <SfInput
                            type="text_field"
                            :valid="!errors[0]"
                            :error-message="$t(errors[0])"
                            v-model="applicantDetails.full_name"
                            name="fullName"
                            required
                            :label="$t('Full name')"
                            class="form__element"
                            autocomplete="off"
                            @blur="saveApplicantDetails()"
                        />
                    </ValidationProvider>
                </div>
                <div class="form-group">
                    <ValidationProvider
                        v-slot="{ errors }"
                        rules="required|email"
                        class="form__element"
                    >
                        <SfInput
                            v-model="applicantDetails.email"
                            type="email"
                            name="email"
                            :label="$t('Email address')"
                            required
                            :valid="!errors[0]"
                            :error-message="$t(errors[0])"
                            @blur="saveApplicantDetails()"
                        />
                    </ValidationProvider>
                </div>
                <div class="form-group">
                    <ValidationProvider
                        v-slot="{ errors }"
                        rules="required|min:3"
                        class="form__element"
                    >
                        <SfInput
                            v-model="applicantDetails.telephone"
                            name="telephone"
                            :label="$t('Phone number')"
                            required
                            :valid="!errors[0]"
                            :error-message="$t(errors[0])"
                            @blur="saveApplicantDetails()"
                        />
                    </ValidationProvider>
                </div>
            </div>
        </form>
        <div v-if="showFinalStepWarning" class="warning-message">{{ $t('Please fill in all required fields') }}</div>
    </div>
</template>

<script>
import {
    defineComponent,
    ref,
    computed
} from '@nuxtjs/composition-api';
import { SfInput, SfModal, SfLoader } from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { email, min } from 'vee-validate/dist/rules';
import { format } from 'date-fns';

extend('email', {
	...email,
	message: 'The email field must be a valid email',
});

extend('min', {
  ...min,
  message: 'This field have more than 3 characters',
});

export default defineComponent({
    name: 'Step3',
    components: {
		SfLoader,
        SfInput,
        SfModal,
        ValidationProvider,
        ValidationObserver,
	},
    props: {
        applicantDetails: Object,
        applicationSubmitted: Boolean,
        showFinalStepWarning: Boolean,
        orders: Array,
	},
    setup(props, { emit }) {
        const saveApplicantDetails = () => {
            emit('saveApplicantDetails', props.applicantDetails);
        };

        const propertiesTodDisplay = [
            'article',
            'warranty_case',
            'warranty',
            'customer_order_number',
            'preferred_course_of_action',
        ];

        const groupedClaims = computed(() => {
            return props.orders.reduce((acc, order) => {
                const key = order.sales_order_number;
                if (!acc[key]) acc[key] = [];
                acc[key].push(order);
                return acc;
            }, {});
        });

        const formatDate = (date) => {
            return date ? format(new Date(date), "MMMM d, yyyy") : "";
        };

        const formatLabel = (key) => {
            return key
                .replace(/_/g, ' ')
                .replace(/\b\w/g, l => l.toUpperCase());
        }

        const formatValue = (value) => {
            if (typeof value === 'object') {
                return value?.label ?? '[object]';
            }
            return value;
        }

        return {
            saveApplicantDetails,
            groupedClaims,
            formatLabel,
            formatValue,
            propertiesTodDisplay,
            formatDate
        };
    },
});
</script>

<style lang="scss" scoped>
    .order-attributes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        align-items: flex-start;

        @media (min-width: 998px) {
            flex-wrap: nowrap;
        }

        div {
            flex-grow: 1;
            width: 100%;
        }
    }

    .half-width {
        .form-group {
            gap: 1rem; /* Adjust spacing between inputs */
            width: 40%;
        }
    }

    .form__element {
        flex: 1; /* Makes inputs take equal width */
    }

    .warning-message {
        color: var(--_c-red-primary);
        min-height: 30px;
    }

    ::v-deep .sf-modal__content {
        padding: 2rem 4rem;
    }


    .order-summary {
        background-color: var(--c-white-blue);
        border-radius: 0.5rem;
        padding: 1.5rem;

        .main-attributes {
            font-size: 18px;

            .order-summary__item {
                max-width: 150px;
            }
        }

        .secondary-attributes {
            .order-summary__item {
                width: 130px;
            }
        }

        .order-summary__item {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-bottom: 1px solid var(--_c-gray-secondary); /* Underline */
            margin-bottom: 0.5rem;
        }

        .subtext {
            font-size: 0.65rem; /* Smaller text */
            color: #888; /* Gray color */
            display: block; /* Ensure it appears above the value */
            margin-bottom: 0.25rem; /* Space between label and value */
            margin-left: 0.35rem; /* Space between label and value */
            font-weight: 500; /* Medium weight */
        }
    }

    .flex {
        flex-wrap: wrap;
    }

</style>